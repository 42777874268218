/* eslint-disable global-require */
import React, { Suspense, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./scss/style.scss";
import Loader from "components/global/Loader";
import PublicRoute from "components/global/Routes/PublicRoute";
import PrivateRoute from "components/global/Routes/PrivateRoute";
import ContentWrapper from "components/global/ContentWrapper";
import Alert from "components/global/Alert/Alert";
import { Chart as ChartJS, ArcElement, Legend, Tooltip } from "chart.js";
import { useHistory } from "react-router-dom";
import userSignal from "signals/User.Signal";
import AuthWrapper from "components/AuthWrapper";
// import { initializeApp } from "firebase/app";

const routes = require("./routes.json");

ChartJS.register(
  // need this for charts to render
  ArcElement,
  Tooltip,
  Legend
);

const LoadingSuspense = () => (
  <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center flex-grow-1">
    <Loader message="Loading..." className="text-center" />
  </div>
);

const UserRoutes = () => {
  const { isSignedIn } = userSignal.value;
  const history = useHistory();
  const currentPath = history.location.pathname;

  return (
    <Switch>
      <ContentWrapper>
        <Alert />
        <Suspense fallback={<LoadingSuspense />}>
          {routes.map((route) => {
            const component = React.lazy(() => import(`./${route.viewPath}`));

            if (route.router === "private") {
              return (
                <PrivateRoute
                  exact
                  path={route.urlPath}
                  component={component}
                  key={route.viewPath}
                />
              );
            }
            if (route.router === "public") {
              return (
                <PublicRoute
                  exact
                  path={route.urlPath}
                  component={component}
                  key={route.viewPath}
                />
              );
            }
            return null;
          })}

          {/* Only redirect if the currentPath is not in routes map */}
          {!routes.some((route) => route.urlPath === currentPath) && (
            <Redirect to={isSignedIn ? "/dashboard" : "/login"} />
          )}
        </Suspense>
      </ContentWrapper>
    </Switch>
  );
};

const App = () => {

  const [isMobile, setIsMobile] = useState(false);

  // Function to check if the device is mobile
  const checkIfMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Mobile device detection based on userAgent
    if (/android/i.test(userAgent)) {
      return true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    setIsMobile(checkIfMobile());
  }, []);

  // Render different content based on device type
  if (isMobile) {
    return (
      <div style={styles.container}>
        <h1>Download our Mobile App</h1>
        <p>Please download our app for the best experience.</p>
        <div style={styles.linkContainer}>
          <a
            href="https://play.google.com/store/apps/details?id=com.iq.neo.resolution&hl=en-US&pli=1"
            style={styles.link}
          >
            Download for Android
          </a>
          <a
            href="https://apps.apple.com/us/app/iq-neo-resolution/id6499366561"
            style={styles.link}
          >
            Download for iPhone
          </a>
        </div>
      </div>
    );
  }

  return (
  <div className="position-relative">
    <AuthWrapper>
      <Router>
        <Route component={UserRoutes} />
      </Router>
    </AuthWrapper>
  </div>
);
}
const styles = {
  container: {
    textAlign: "center",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  linkContainer: {
    marginTop: "20px",
  },
  link: {
    display: "block",
    margin: "10px 0",
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    textDecoration: "none",
    borderRadius: "5px",
  },
};
export default App;
